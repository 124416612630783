import {
  GET_BANNERS,
  GET_STORES,
  LOADING,
  LOADED,
  GET_BRANCHORDER,
  GET_DELIVERYBOY,
  GET_EXECUTIVES,
  SET_CURRENT_RESTAURANT,
  GET_PRODUCTS,
  SET_PAGINATION,
  SET_DBANALYSIS,
  GET_PROMOCODES,
  GET_MENU,
  GET_RESTAURANT_CATEGORIES,
  GET_PLACES,
} from "./constants";

const initialState = {
  stores: [],
  loading: true,
  branchBanners: [],
  orders: [],
  deliveryBoys: [],
  executives: [],
  currentStore: "",
  products: [],
  pagination: {},
  DBAnalysis: {},
  promocodes: [],
  productPagination: [],
  menu: [],
  restaurantCategories: [],
  places: [],
};

export default function store(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };

    case GET_RESTAURANT_CATEGORIES:
      return {
        ...state,
        restaurantCategories: payload,
      };

    case GET_STORES:
      return {
        ...state,
        loading: false,
        stores: payload.data,
        pagination: { ...state.pagination, total: payload.count },
      };
    case GET_MENU:
      return {
        ...state,
        menu: payload,
      };
    case GET_BRANCHORDER:
      return {
        ...state,
        pagination: { ...state.pagination, total: payload.count },
        orders: payload.data,
      };
    case GET_DELIVERYBOY:
      return {
        ...state,

        deliveryBoys: payload.data,
        pagination: { ...state.pagination, total: payload.count },
      };
    case SET_CURRENT_RESTAURANT:
      return {
        ...state,
        currentStore: payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload.data,
        loading: false,
        productPagination: payload.total,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, page: payload },
      };
    case GET_EXECUTIVES:
      return {
        ...state,
        pagination: { ...state.pagination, total: payload.count },
        executives: payload.data,
      };
    case SET_DBANALYSIS:
      return {
        ...state,
        DBAnalysis: payload,
      };
    case GET_PROMOCODES:
      return {
        ...state,
        promocodes: payload,
      };
    case GET_PLACES:
      return {
        ...state,
        places: payload,
      };
    default:
      return state;
  }
}
