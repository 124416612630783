export const GET_STORES = "GET_STORES";
export const STORE_ERROR = "STORE_ERROR";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const ADD_DELIVERYBOY = "ADD_DELIVERYBOY";
export const GET_DELIVERYBOY = "GET_DELIVERYBOY";
export const GET_BRANCHORDER = "GET_BRANCHORDER";
export const GET_EXECUTIVES = "GET_EXECUTIVES";
export const GET_BANNER = "GET_BANNER";
export const BANNER_ERROR = "BANNER_ERROR";

export const SET_CURRENT_RESTAURANT = "SET_CURRENT_RESTAURANT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_DBANALYSIS = "SET_DBANALYSIS";
export const GET_PROMOCODES = "GET_PROMOCODES";

export const GET_MENU = "GET_MENU";
export const GET_RESTAURANT_CATEGORIES = "GET_RESTAURANT_CATEGORIES";

export const GET_PLACES = "GET_PLACES";
